import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <footer class="py-4 mt-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>Makeshift Media Group</h4>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="mailto:makeshiftmediagroup@gmail.com"
                  >
                    <i className="fas fa-envelope-square" id="emailus"></i>
                    Email
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/testmonials">
                    Testmonials
                  </a>
                </li>
                <li className="nav-item">Location: Madison WI</li>
              </ul>
            </div>
            <div className="col">
              <a
                className="btn btn-vimeo btn-floating m-1"
                href="http://www.vimeo.com/makeshiftmediagroup"
                role="button"
                target="_blank"
              >
                <i className="fab fa-vimeo"></i>
              </a>
              <a
                className="btn btn-youtube btn-floating m-1"
                href="http://www.youtube.com/makeshiftmediagroup"
                role="button"
                target="_blank"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                className="btn btn-facebook btn-floating m-1"
                href="http://www.facebook.com/makeshiftmediagroup"
                role="button"
                target="_blank"
              >
                <i id="facebook" className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-twitter btn-floating m-1"
                href="https://twitter.com/mkshftmediagrp"
                role="button"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-linkedin btn-floating m-1"
                href="https://www.linkedin.com/in/makeshiftmediagroup/"
                role="button"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <hr />
          <div className="row">
            <p className="text-center footer">
              &copy; {new Date().getFullYear()} Makeshift Media Group | All
              rights reserved
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
