import React, { Component, U } from "react";
import ServiceData from "../Data/data.json";

class Services extends Component {
  render() {
    return (
      <div className="container">
        <header>
          <h1 className="text-center m-4">Services</h1>
          <p>
            MakeShiftMedia Group is a media production company focused providing
            high quality and affordable services.
          </p>
        </header>
        <main>
          <div class="row row-cols-1 row-cols-md-2 g-4">
            {ServiceData.map((service) => (
              <div class="col-lg-4 col-6">
                <div class="card h-100" key={service.id}>
                  <img
                    src={service.picture}
                    class="card-img-top"
                    alt={service.title}
                  />
                  <div class="card-body text-center">
                    <h2 class="card-title">{service.title}</h2>
                    <p class="card-text">{service.description}</p>
                  </div>
                  <a
                    target="_blank"
                    href={service.video}
                    alt={service.title}
                    className="btn btn-secondary"
                  >
                    View Sample
                  </a>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    );
  }
}

export default Services;
