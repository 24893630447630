import React, { Component, useRef, useState } from "react";
import emailjs from "emailjs-com";

const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } =
  process.env;
// class ContactUs extends Component {

//   render() {
const Result = () => {
  return (
    <div className="alert alert-success">
      <p>Thank you for Contacting Us. We will get back to as soon as we can!</p>
    </div>
  );
};
const ContactUs = () => {
  let serviceChoices = [
    "Theatre",
    "Business/Promotional",
    "Documentary",
    "Live Events",
    "Other/Not Sure",
  ];
  const form = useRef();
  let data = null;
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    console.log(`${process.env}`);
    e.preventDefault();
    emailjs
      .sendForm(
        `${REACT_APP_SERVICE_ID}`,
        `${REACT_APP_TEMPLATE_ID}`,
        form.current,
        `${REACT_APP_USER_ID}`
      )
      .then(
        (result) => {
          data = result;
          console.log(data.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };
  setTimeout(() => {
    showResult(false);
  }, 5000);
  return (
    <div className="container">
      <div className="row">
        <div
          id="contact-us"
          className="card col-md-6 offset-md-3 col-lg-8 offset-lg-2 mt-5"
        >
          <header className="card-header text-center">
            <h1>Contact Us</h1>
            <p>
              Please contact us for any questions or inquires. We will get back
              to you as soon as we can.
            </p>
          </header>

          <main>
            {result ? <Result /> : null}
            <form ref={form} onSubmit={sendEmail} className="card-body">
              <div className="row mb-3">
                <label htmlFor="fullName" className="col-md-3 col-form-label">
                  Full Name
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    required
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label htmlFor="email" className="col-md-3 col-form-label">
                  Email
                </label>
                <div className="col-md-9">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="phonenumber"
                  className="col-md-3 col-form-label"
                >
                  Phone
                </label>
                <div className="col-md-9">
                  <input
                    type="tel"
                    className="form-control"
                    id="phonenumber"
                    name="phone"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label htmlFor="subject" className="col-md-3 col-form-label">
                  Subject
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    id="subject"
                    required
                  />
                </div>
              </div>
              <fieldset className="row mb-3">
                <legend className="col-form-label col-md-3 pt-0">Radios</legend>
                <div className="col-md-9">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="serviceChoice"
                      id="gridRadios1"
                      value={serviceChoices[0]}
                    />
                    <label className="form-check-label" htmlFor="gridRadios1">
                      Business/Promotional
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="serviceChoice"
                      id="gridRadios2"
                      value={serviceChoices[1]}
                    />
                    <label className="form-check-label" htmlFor="gridRadios2">
                      Documentary
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="serviceChoice"
                      id="gridRadios3"
                      value={serviceChoices[2]}
                    />
                    <label className="form-check-label" htmlFor="gridRadios3">
                      Live Show on Stage
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="serviceChoice"
                      id="gridRadios4"
                      value={serviceChoices[3]}
                    />
                    <label className="form-check-label" htmlFor="gridRadios4">
                      Live Streaming
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="serviceChoice"
                      id="gridRadios5"
                      checked
                      value={serviceChoices[4]}
                    />
                    <label className="form-check-label" htmlFor="gridRadios5">
                      Other/ Not Sure
                    </label>
                  </div>
                </div>
              </fieldset>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  name="message"
                  required
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
            {data}
          </main>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
