import React, { Component } from "react";
import "../Pages.css";
export default class Testmonial extends Component {
  render() {
    return (
      <section className="container">
        <h1 className="text-center m-4">Testmonials</h1>
        <article>
          <h2>LinkedIn Recommendations</h2>
          <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
          <ul className="section-info" tabindex="-1">
            <li id="ember361" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/jeremy-blunt-b6a53525/"
                  className="pv-recommendation-entity__member"
                  target="_blank"
                >
                  <img
                    src="https://media-exp1.licdn.com/dms/image/C4D03AQEp_6T0fWXAOA/profile-displayphoto-shrink_100_100/0/1517270474345?e=1649289600&amp;v=beta&amp;t=LZoZpbjUoiKDhIJw0SQ-gTqV_WusDOXuc1c0TzsqVTU"
                    alt="Jeremy Blunt"
                    className="img-circle"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Jeremy Blunt</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Direct Response/Online Marketer and Professional
                      Dog-Petter, Treat Giver
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      June 1, 2015, Jeremy was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p className="pv-recommendation-entity__text relative">
                  Worked with Ben on NUMEROUS videos for our company. Videos and
                  audio turned out above and beyond what we expected and best of
                  all the customers LOVED EM'! Ben's our go-to guy when it comes
                  to video work of any kind. Highly recommended.{" "}
                  <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember365" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/kristin-connor-pendl-0b197117/"
                  target="_blank"
                  className="ember-view pv-recommendation-entity__member"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C4D03AQGtSiO-TV6SnA/profile-displayphoto-shrink_100_100/0/1516768103312?e=1649289600&amp;v=beta&amp;t=fifC-ijLhtrg_fR9G_1OLrujXCIPf5QfAmci1RPHyOw"
                    loading="lazy"
                    height="56"
                    alt="Kristin Connor Pendl"
                    id="ember367"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Kristin Connor Pendl</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Owner and Acupuncturist, Dane County Family Acupuncture
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      April 23, 2012, Kristin was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  Ben did an excellent job photographing my wedding ceremony in
                  March! He took beautiful shots of me and my husband all
                  throughout the ceremony and of the whole family afterwards. It
                  was really fun seeing all the candid shots he took of our
                  guests before I even arrived at the church. He was great at
                  gathering all the various family members for the formal shots
                  and managing the poses. The photos are everything we hoped
                  for! <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember398" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/ricmc/"
                  id="ember399"
                  className="ember-view pv-recommendation-entity__member"
                  target="_blank"
                >
                  <img
                    width="48"
                    src="https://media-exp1.licdn.com/dms/image/C4E03AQGgamNupgS17g/profile-displayphoto-shrink_100_100/0/1516332943815?e=1649289600&amp;v=beta&amp;t=hz_g-59zPw-p0HfYeNaofZ0clmP5LyERDc6j6Ypd5ww"
                    loading="lazy"
                    height="48"
                    alt='Eric J. "Ric" M. profile picture'
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Eric J. "Ric" M.</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Senior Technical Field Support Engineer at Medtronic,
                      Professional Stand-up Comedian, Filmmaker, Actor,
                      Musician, Former Attorney and Comedy Club Owner.
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      August 17, 2011, Eric J. "Ric" was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  Ben is a talented videographer who worked for me as a camera
                  operator and cinematographer on my film. He is creative, has
                  an eye for detail, and would be a valuable asset on any
                  project he joins. I hope to work with him again in the
                  future... <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember402" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/danodegard/"
                  target="_blank"
                  className="ember-view pv-recommendation-entity__member"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C5103AQHPxV13GwYq7Q/profile-displayphoto-shrink_100_100/0/1516338254444?e=1649289600&amp;v=beta&amp;t=0H3seKxCoTXMNUHz_m3r-ce4SR3ZXaeQbEbzay91mlY"
                    loading="lazy"
                    height="56"
                    alt="Dan Odegard"
                    id="ember404"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Dan Odegard</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Sales and Marketing Manager
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      July 26, 2011, Dan was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  I have hired been multiple times for our video demonstrations.
                  He is professional, on time, does great work with a fast
                  turnaround. He has come through every time we have needed him
                  and look forward to working with Ben on our next project!{" "}
                  <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember406" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/timmyc/"
                  id="ember407"
                  className="ember-view pv-recommendation-entity__member"
                  target="_blank"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C4D03AQEuR0oVF324ow/profile-displayphoto-shrink_100_100/0/1576704367761?e=1649289600&amp;v=beta&amp;t=75BP3AezW6-pU5CaLXCN2Pviq9CNauTj-Zar9PB3syM"
                    loading="lazy"
                    height="56"
                    alt="Tim Caravia"
                    id="ember408"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Tim Caravia</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Drives better learning and performance in the workplace
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      July 18, 2011, Tim was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  With short notice, Ben was able to assemble a group of actors
                  and film crew, find a location, and film three short training
                  videos for my company, teksoft ventures. Within a few days of
                  meeting Ben, he delivered three great videos to us all within
                  the budget discussed. We couldn't have asked for more.{" "}
                  <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember410" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/jan-levine-thal-4462421/"
                  id="ember411"
                  className="ember-view pv-recommendation-entity__member"
                  target="_blank"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C5103AQGh-9UZeEgufg/profile-displayphoto-shrink_100_100/0/1516240386443?e=1649289600&amp;v=beta&amp;t=Da45q1NWmde682EIeShagqAVNhWLEENRzjx64-A0EMM"
                    loading="lazy"
                    height="56"
                    alt="Jan Levine Thal"
                    id="ember412"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Jan Levine Thal</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Novelist at Van Velzer publishing
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      July 16, 2011, Jan was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  Ben is an excellent videographer who made trailers for several
                  theatrical ventures I was involved with. His videos are
                  interesting, well-shot and inventive. He's intelligent and
                  easy to work with. <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember414" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/douggordon/"
                  id="ember415"
                  className="ember-view pv-recommendation-entity__member"
                  target="_blank"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C5603AQHwCqgxW0baDg/profile-displayphoto-shrink_100_100/0/1516342746757?e=1649289600&amp;v=beta&amp;t=A3rM07qQWzLGvELzbWtE2fandU94nCejjobE_Ao7yFg"
                    loading="lazy"
                    height="56"
                    alt="Doug Gordon"
                    id="ember416"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Doug Gordon</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Host/Producer, BETA Wisconsin Public Radio
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      February 10, 2010, Doug worked with Ben in different
                      groups
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  Ben is a very talented, very creative film-maker. He was
                  instrumental in filming, editing and developing creative
                  content for the indie film, "The Zombeatles: All You Need Is
                  Brains." Ben edited the entire film, shot the film and did the
                  sound design. He also acted in the film. Ben has tremendous
                  energy, enthusiasm, initiative and creativity. He's truly a
                  pleasure to work with. The film would not have been the
                  success it is without Ben's dedication and vision.
                </p>
                <p>
                  I also acted for Ben in a short film called "Southern
                  Discomfort." This was another very enjoyable experience. Ben
                  has a tremendous eye for visuals and what makes for a good
                  shot. He's also a very talented and creative writer. I can't
                  say enough good things about Ben Wydeven's talents, skills,
                  experience and work ethic. If you hire only one film-maker for
                  your next project, Ben Wydeven is the one to hire.{" "}
                  <i className="fab fa-linkedin-in"></i>
                </p>
              </div>
            </li>
            <li id="ember473" className="pv-recommendation-entity ember-view">
              <div className="pv-recommendation-entity__header">
                <a
                  href="https://www.linkedin.com/in/grant-w-petty-24068310/"
                  id="ember474"
                  className="ember-view pv-recommendation-entity__member"
                >
                  <img
                    width="56"
                    src="https://media-exp1.licdn.com/dms/image/C5603AQExEx6VGXK82A/profile-displayphoto-shrink_100_100/0/1517728358657?e=1649289600&amp;v=beta&amp;t=CHE5GBvEFjdiPxOYY1Cc9Sh0oosJUZf02Eg7bgkdfa0"
                    loading="lazy"
                    height="56"
                    alt="Grant W. Petty"
                    id="ember475"
                    className="lazy-image ember-view EntityPhoto-circle-4 fl"
                  />

                  <div className="pv-recommendation-entity__detail">
                    <h3 className="t-black t-bold">Grant W. Petty</h3>
                    <p className="pv-recommendation-entity__headline t-14 t-black t-normal pb1">
                      Professor of Atmospheric Science, UW-Madison
                    </p>
                    <p className="t-12 t-black--light t-normal">
                      February 9, 2010, Grant W. was a client of Ben’s
                    </p>
                  </div>
                </a>
              </div>
              <div className="pv-recommendation-entity__highlights">
                <p>
                  We hired Ben's company to conduct a two-camera video shoot of
                  a live performance of our rock band. Included in the package
                  was audio/video editing, the creation of a video montage from
                  footage spanning over an hour, the creation of a YouTube
                  channel for the band and a demo DVD. Ben did good quality work
                  at good price, and he was reliable and responsive even months
                  after the initial phase of the work was done.{" "}
                  <a href="https://www.linkedin.com/in/makeshiftmediagroup/#ember358">
                    {" "}
                    Source <i className="fab fa-linkedin-in"></i>
                  </a>
                </p>
              </div>
            </li>
          </ul>
          <p className="text-end">
            <i className="fa fa-quote-right fa-3x" aria-hidden="true"></i>
          </p>
        </article>
      </section>
    );
  }
}
