import React, { Component } from "react";
import { Link } from "react-router-dom";
class MainPage extends Component {
  render() {
    return (
      <main className="showcase">
        <div className="overlay"></div>
        <div className="text">
          <h2>Makeshift Media Group</h2>
          <h3>Located in Madison, WI</h3>
          <p>
            Our company's business model revolves around our passion to tell a
            story. With both videography & photography, there is always a story
            to tell, whether your project is a short introductory video about
            your small business, or a first hand account of World War II...
          </p>
          <p>There's always a story.</p>
          <p className="text-center mt-4"></p>
          <Link className="btn btn-dark" to="/services">
            Portfolio
          </Link>
        </div>
      </main>
    );
  }
}

export default MainPage;
