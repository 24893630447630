import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainPage from "./components/Pages/MainPage";
import ContactUs from "./components/Pages/ContactUs";
import Services from "./components/Pages/Services";
import Header from "./components/Layout/Header";
import Testmonial from "./components/Pages/Testmonial";
import Footer from "./components/Layout/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/services" component={Services} />
          <Route path="/testmonials" component={Testmonial} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
